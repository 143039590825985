.App {
  background-color: white;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

img {
  max-width: 98%;
  max-height: 98%;
  cursor: pointer;
}

.logo {
  scale: 85%;
  cursor: pointer;
  transition: transform 0.3s;
  opacity: 25%;
}

.button {
  cursor: pointer;
  transition: transform 0.3s;
  scale: 45%;
  opacity: 25%;
}

.logo:hover,
.button:hover {
  transform: scale(1.05);
}